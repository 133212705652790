@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary: '#3559F7';
  --primary-light: '#E4E9FF';
  --primary-disabled: '#8F909C';
  --primary-active: '#1532B0';
  --secondary: '#3C3E4B';
  --secondary-2: '#A7DBDE';
  --secondary-2-light: '#F0F8F8';
  --secondary-disabled: '#AAACBB';
  --gray: '#8F909C';
  --gray-dark: '#6C6C6C';
  --gray-light: '#C4C6CC';
  --gray-light-2: '#F6F6FA';
  --error: '#EF233C';
  --flat: '#F5F5FA';
  --footer: '#181920';
  --font: 'Inter';
  --details-nav-bg: '#F6F6FA';
}

@layer base {
  @font-face {
    font-family: 'Inter';
    font-weight: 400;
    src: url('/es/assets/fonts/Inter-Regular.ttf') format('opentype');
    font-display: swap;
  }

  @font-face {
    font-family: 'Inter';
    font-weight: 600;
    src: url('/es/assets/fonts/Inter-SemiBold.ttf') format('opentype');
    font-display: swap;
  }

  @font-face {
    font-family: 'Inter';
    font-weight: 700;
    src: url('/es/assets/fonts/Inter-Bold.ttf') format('opentype');
    font-display: swap;
  }

  :root {
    --primary: theme('colors.primary');
  }

  html {
    scroll-behavior: smooth;
    -webkit-tap-highlight-color: transparent;
  }

  div {
    scroll-behavior: smooth;
  }

  h1 {
    @apply text-primary md:text-5xl;
  }

  h2 {
    @apply text-primary;
  }

  svg {
    fill: currentColor;
  }

  p {
    white-space: pre-line;
  }
  ol {
    list-style: decimal inside;
  }
}

.PhoneInputInput {
  padding: 0.5rem 0.75rem;
  border-radius: 0.5rem;
}

.PhoneInputInput::placeholder {
  color: var(--gray);
}

.no-scroll-bar::-webkit-scrollbar {
  display: none;
}

.required::before {
  color: red;
  content: '*';
  font-size: 16px;
  margin-right: 5px;
}

.swiper .swiper-pagination-progressbar {
  bottom: 0px;
  top: auto !important;
}
.swiper .swiper-button-next,
.swiper .swiper-button-prev {
  z-index: 9999;
  position: absolute;
  height: 100%;
  top: 0px;
  width: 20%;
  margin-top: 0px;
}
.swiper .swiper-button-prev {
  left: 0px;
}
.swiper .swiper-button-next {
  right: 0px;
}

.swiper .swiper-button-next:after,
.swiper .swiper-button-prev:after {
  content: '' !important;
}

.line-clamp-1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  * {
    display: inline;
  }
}
.line-clamp-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  * {
    display: inline;
  }
}
.line-clamp-3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  * {
    display: inline;
  }
}

.shadow-xs {
  box-shadow: 0px 0px 15px 0px #0000000d;
}

.border-1 {
  border-width: 1px;
}

.nav-bg {
  background-color: var(--details-nav-bg);
}

.z-top {
  z-index: 999999;
}

.border-light-gray {
  border-color: #d9d9d9;
}

@media (max-width: 768px) {
  .nav-bg {
    padding-left: 1rem;
  }
}

.list-carousel {
  list-style-type: disc;
  padding-left: 1rem;
}

.shadowTransition {
  transition: box-shadow 0.3s ease-in-out;
}
